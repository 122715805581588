const env = {
  applicationName: 'KaBot',
  production: true,
  apiProtocol: 'http',
  platformId: '3ba3da75-01e0-49b2-a2c1-b33e40cc2624',
  baseApi: 'https://api.kaex.me',
  backendVersion: '1.0.0',
  serviceRegisterUrl: 'https://api.kaex.me',
};

export { env }
