import { isBrowser } from '@revolve-app/app/core/utils/is.browser';

/**
 * Enum for storage keys
 */
export enum StorageKeys {
  USEROBJECT = 'user_object',
  FEATURES = 'features',
  TOKEN = 'auth_token',
  SESSION = 'session',
  PLATFORMID = 'platform_id',
  LANGUAGE = 'language',
  PHONE_NUMBER = 'phone_number',
  VERIFICATION_CODE = 'version_code',
  IP_SESSION_KEY = 'ip_session_key',
  PLATFORM_NAME = 'platform_name',
}

/**
 * Service for handling storage
 */
export class StorageService{
  /**
   * Reference to localStorage if exists, else null
   */
  static localStorage = isBrowser() ? localStorage: null;

  /**
   * Retrieve a value from localStorage
   * @param key - The StorageKey for item to be retrieved
   * @returns - The item from localStorage as a string or null if not found
   */
  static getItem(key: StorageKeys){
    return StorageService.localStorage?.getItem(key);
  }

  /**
   * Retrieve a value from localStorage
   * @param key - The StorageKey for item to be retrieved
   * Dispatches a storage event on Window objects holding an equivalent Storage object.
   */
  static removeItem(key: StorageKeys){
    return StorageService.localStorage?.removeItem(key);
  }

  /**
   * Store a value in localStorage as string
   * @param key - The StorageKey for item to be stored
   * @param value - The value to be stored
   */
  static setItem(key: StorageKeys, value: string){
    if(StorageService.localStorage){
      StorageService.localStorage.setItem(key, value);
    }
  }

  /**
   * Store a value in localStorage as JSON string
   * @param key - The StorageKey for item to be stored
   * @param value - The JSON value to be stored
   */
  static setJsonItem(key: StorageKeys, value: any){
    if(value && StorageService.localStorage){
      StorageService.setItem(key, JSON.stringify(value));
    }
  }

  /**
   * Retrieve a JSON value from localStorage
   * @param key - The StorageKey for item to be retrieved
   * @returns - The item from localStorage parsed as JSON or null if not found
   */
  static getJsonItem(key: StorageKeys){
    if(StorageService.localStorage){
      let item = StorageService.getItem(key);
      if(item){
        return JSON.parse(item);
      }
    }
    return null;
  }
}
