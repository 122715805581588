export enum MicroService {
    ACCOUNT= 'account-microservice',
    PLATFORM = 'platform-microservice',
    RATES = 'rates-microservice',
    ORDER = 'order-microservice',
    EMAIL = 'email-microservice',
    FILE = 'file-microservice',
    CHAT = 'chat-microservice',
    SUBSCRIPTIONS = 'subscribers-microservice',
    WALLET = 'wallet-microservice',
    AISUPPORT = 'ai-support-microservice',
    TRANSACTIONS = 'transactions-microservice',
    TELEGRAM = 'telegram-bot-microservice',
    EXCHANGE = 'exchange-microservice',
}
