import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";
import {env} from "@revolve-app/env/env";
import {TBaseSuccessResponse} from "@revolve-app/app/core/metadata/types/base.models";

export interface TRequestConfigs {
  showLoader?: boolean,
  token?: string | null,
  showSuccess?: boolean,
  platformId?: string,
  baseUrl?: string | null,
  contentType?: string,
  accept?: string,
  lang?: string
}
export interface TBaseUrlObj{
  ip: string,
  name: string,
  port: string,
  version: string
}
const defaultConfigs : TRequestConfigs = {
  showLoader: false,
  showSuccess: true,
  token: StorageService.getItem(StorageKeys.TOKEN),
  platformId: env.platformId,
  baseUrl: env.baseApi,
  contentType: 'application/json',
  accept: 'application/json',
  lang: StorageService.getItem(StorageKeys.LANGUAGE) || 'en'
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpSettings = defaultConfigs;
  constructor(private http: HttpClient, ) {}

  makeBaseUrl(baseUrlObj: TBaseUrlObj){
    if(!baseUrlObj){
      return '';
    }
    return env.apiProtocol + '://' + baseUrlObj.ip + ':' + baseUrlObj.port
  }

  prepareHeaders (configs: TRequestConfigs){
    const headers: any = {
      "Show-Loader": `${configs.showLoader}`,
      "Show-Success": `${configs.showSuccess}`,
      'platformId': configs.platformId,
      'language': configs.lang
    };
    if (!configs.token) {
      configs.token = StorageService.getItem(StorageKeys.TOKEN)
    }
    if(configs.token){
      headers.Authorization = 'Bearer ' + configs.token;
    }
    if(headers.Authorization){
      delete headers.platformId
    }
    return new HttpHeaders(headers);
  }

  get<T>(endpoint: string, params?: any, configs: TRequestConfigs = defaultConfigs): Observable<T> {
    const url = `${configs.baseUrl || env.baseApi}/${endpoint}`;
    const headers = this.prepareHeaders({...this.httpSettings, ...configs});
    return this.http.get<T>(url, { params, headers: headers }).pipe(
      map(response => (response as TBaseSuccessResponse).data as T),
      catchError(error => {
        //console.error('An error occurred:', error);
        throw new Error(error.message || 'Server Error');
      })
    )
  }

  post<T>(endpoint: string, params?: any, configs: TRequestConfigs = defaultConfigs): Observable<T> {
    const url = `${configs.baseUrl || env.baseApi}/${endpoint}`;
    const headers = this.prepareHeaders({...this.httpSettings, ...configs});
    return this.http.post<T>(url, { ...params }, {headers: headers}).pipe(
      map(response => (response as TBaseSuccessResponse).data as T),
      catchError(error => {
        //console.error('An error occurred:', error);
        throw new Error(error.message || 'Server Error');
      })
    )
  }
  put<T>(endpoint: string, params?: any, configs: TRequestConfigs = defaultConfigs): Observable<T> {
    const url = `${configs.baseUrl || env.baseApi}/${endpoint}`;
    const headers = this.prepareHeaders({...this.httpSettings, ...configs});
    return this.http.put<T>(url, { ...params }, {headers: headers}).pipe(
      map(response => (response as TBaseSuccessResponse).data as T),
      catchError(error => {
        //console.error('An error occurred:', error);
        throw new Error(error.message || 'Server Error');
      })
    )
  }
  delete<T>(endpoint: string, configs: TRequestConfigs = defaultConfigs): Observable<T> {
    const url = `${configs.baseUrl || env.baseApi}/${endpoint}`;
    const headers = this.prepareHeaders({...this.httpSettings, ...configs});
    return this.http.delete<T>(url, {headers: headers}).pipe(
      map(response => (response as TBaseSuccessResponse).data as T),
      catchError(error => {
        //console.error('An error occurred:', error);
        throw new Error(error.message || 'Server Error');
      })
    )
  }

  postFormData<T>(endpoint: string, formData?: FormData, configs: TRequestConfigs = defaultConfigs): Observable<T> {
    const url = `${configs.baseUrl || env.baseApi}/${endpoint}`;
    const headers = this.prepareHeaders({...this.httpSettings, ...configs});
    return this.http.post<T>(url, formData, {headers: headers}).pipe(
      map(response => (response as TBaseSuccessResponse).data as T),
      catchError(error => {
        //console.error('An error occurred:', error);
        throw new Error(error.message || 'Server Error');
      })
    )
  }
}
